<template>
  <div
    class="relative overflow-visible tile-frame rounded-alt-lg"
    :class="{
      slim: !isMobile && size === ETileSize.SLIM,
      normal: isMobile || size === ETileSize.NORMAL,
      large: !isMobile && size === ETileSize.LARGE,
      shop: !isMobile && size === ETileSize.SHOP,
      'shop-single-mobile': size === ETileSize.SHOP_SINGLE_MOBILE,
      'shop-advanced': !isMobile && size === ETileSize.SHOP_ADVANCED,
      'shop-mobile': isMobile && size === ETileSize.SHOP,
      'bg-background-base': !transparent,
      'campaign-single-mobile': size === ETileSize.CAMPAIGN_SINGLE_MOBILE,
    }"
  >
    <div
      class="flex flex-col w-full h-full cursor-pointer wrapper"
      @click="$emit('click')"
    >
      <div v-if="$slots.image" class="relative flex-shrink-0 imagery">
        <slot name="image" />
      </div>

      <div class="h-full content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script lang="ts"></script>
<script setup lang="ts">
import { ETileSize } from '~~/src/@types/tile-size';
import { mq_breakpointIsMobile } from '~~/src/injectionSymbols';

defineProps({
  size: {
    type: String as PropType<ETileSize>,
    required: false,
    default: ETileSize.NORMAL,
  },
  transparent: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits<{
  (e: 'click'): void;
}>();

const isMobile = inject(mq_breakpointIsMobile);
</script>
<style scoped lang="postcss">
.wrapper {
  @apply h-full w-full flex flex-col cursor-pointer;
}
.slim {
  @apply col-span-12 min-h-[288px] flex;

  .wrapper {
    @apply min-h-[288px] flex flex-row;
    .imagery {
      @apply w-tile-slim-imagery;
    }
    .content {
      @apply w-2/3;
    }
  }
}

.normal {
  @apply col-span-12 md:col-span-6 touch:md:col-span-4 lg:col-span-4 min-h-128 cursor-pointer;

  .wrapper {
    .imagery {
      @apply h-[256px];
    }
  }
}

.shop {
  @apply col-span-6 [@media(min-width:1024px)]:col-span-6 xl:!col-span-3 min-h-fit md:min-h-[576px] cursor-pointer max-w-[330px] 2xl:w-[330px];

  .wrapper {
    .imagery {
      @apply h-[256px];
    }
  }
}

.shop-single-mobile {
  @apply md:w-[330px] [@media(min-width:1024px)]:col-span-4 xl:!col-span-3 min-h-fit md:min-h-[576px] cursor-pointer;
}

.campaign-single-mobile {
  @apply !col-span-12 lg:!col-span-6 2xl:w-[330px] xl:!col-span-3 min-h-fit md:min-h-[576px] cursor-pointer;
}

.shop-mobile {
  @apply !col-span-6 !min-h-fit;
}

.large {
  @apply col-span-12 min-h-[384px] flex;

  .wrapper {
    @apply h-[384px] flex;
    .imagery {
      @apply w-tile-large-imagery;
    }
    .content {
      @apply w-1/3;
    }
  }
}
</style>
